import React, { useState } from 'react'
import { gql, request } from 'graphql-request'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { getApiEndpoint } from '../../utils/endpoints'

const Content = ({ location }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(
    location.search === '?dev=true' ? false : true
  )
  const [data, setData] = useState({formulaire: "Collaborate", type: open ? 'Developer' : 'Designer'})

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.hasOwnProperty("checked") ? e.target.checked : e.target.value
    })
  }

  function handleChecked() {
    setOpen((open) => !open)
    setData({
      ...data,
      type: !open ? 'Developer' : 'Designer'
    })

  }

  const dev_list = t('page_collaborate_developer_list', {
    returnObjects: true,
  })
  const designer_list1 = t('page_collaborate_designer_list', {
    returnObjects: true,
  })
  const designer_list2 = t('page_collaborate_designer_list_2', {
    returnObjects: true,
  })

  return (
    <>
      <img
        className={'logo__develop'}
        src={'/img/with-banner/developer/background-2.svg'}
        alt={'Logo Quintyss'}
      />

      <div className={'slide__button'}>
        <input
          type="checkbox"
          id="lol-checkbox"
          onChange={handleChecked}
          defaultChecked={open}
        />

        <label id="button" htmlFor="lol-checkbox">
          <span id="knob"></span>
          <span id="subscribe">{t('page_collaborate_designer')}</span>
          <span id="alright">{t('page_collaborate_developer')}</span>
        </label>
      </div>
      <section className="intro_developer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={'title_developer'}>
                {open ? (
                  <h4 className="intro__title section-title">
                    <Trans>page_collaborate_developer_intro_title</Trans>
                  </h4>
                ) : (
                  <h4 className="intro__title section-title">
                    <Trans>page_collaborate_designer_intro_title</Trans>
                  </h4>
                )}

                <img
                  className={'logo'}
                  src={'/img/with-banner/developer/logo.svg'}
                  alt={'Logo Quintyss'}
                />
              </div>
            </div>

            <div className="col-12">
              <p className="intro__text">
                {!open && <Trans>page_collaborate_developer_subtitle</Trans>}
                {open && <Trans>page_collaborate_designer_subtitle</Trans>}
              </p>

              {!open && (
                <>
                  <p className="intro__text">
                    <Trans>page_collaborate_developer_descr</Trans>
                  </p>
                  <ul >
                    {dev_list.map((item, i) => (
                      <li style={{fontSize:"16px"}} key={i}><p>{item}</p></li>
                    ))}
                  </ul>
                </>
              )}

              {open && (
                <>
                  <p className="intro__text">
                  <Trans>page_collaborate_designer_descr</Trans>
                  </p>
                  <ol>
                    {designer_list1?.map((item, i) => (
                        <li style={{fontSize:"16px"}} key={i}><p>{item}</p></li>
                    ))}
                  </ol>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className={'visuel_identity'}>
        <div className={'container'}>
          <form
            name={'Collaborate'}
            onSubmit={async (e) => {
              e.preventDefault();
              await fetch(`${getApiEndpoint()}/quintyss-contact`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              });
              window.location = location.pathname + (location.pathname.endsWith("/") ? '' : '/') + '?success=true';
              return false;
            }}
          >
            <input
              type="hidden"
              id="type"
              name="type"
              value={open ? 'Developer' : 'Designer'}
            />

            <div className="contact__form no-border  ">
              {open && (
                <div className="form-group mb-5 input-lg">
                  <h4 className="functionnality__title section-title">
                    {t('page_collaborate_designer_portfolio')}
                  </h4>
                  <label htmlFor="lien_du_site">
                    {t('page_collaborate_designer_portfolio_label')}
                  </label>
                  <input
                    type="text"
                    onChange={updateData}
                    name="lien_du_site"
                    className="form-control"
                  />
                </div>
              )}

              <div className="form-group input-lg mb-5">
                <h4 className="contact__title section-title">
                  {t('page_translate_form_label_5')}
                </h4>
                <label htmlFor="lastName">{t('constant_label_lastname')}</label>
                <input
                  type="text"
                  id="lastName"
                  name="nom"
                  required={true}
                  onChange={updateData}
                  className="form-control mb-3"
                />
                <label htmlFor="firstName">
                  {t('constant_label_firstname')}
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="prenom"
                  required={true}
                  onChange={updateData}
                  className="form-control mb-3"
                />
                <label htmlFor="email">{t('constant_label_email')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required={true}
                  onChange={updateData}
                  className="form-control mb-3"
                />
                <label htmlFor="phone">{t('constant_label_tel')}</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  required={true}
                  onChange={updateData}
                  className="form-control mb-3"
                />
              </div>
              <div className="form-group mb-5  ">
                <label htmlFor="message">{t('page_collaborate_message')}</label>
                <textarea
                  id="link"
                  name="message"
                  required={true}
                  onChange={updateData}
                  className="form-control"
                  rows="4"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: '10px' }}
              >
                {t('page_collaborate_btn')}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default Content
