import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { graphql } from 'gatsby'
import Banner from '../components/Collaborate/Banner'
import Content from '../components/Collaborate/Content'

import Success from '../components/Form/Success'

export default function Collaborate({ pageContext, location }) {
  return (
    <Layout
      className="with-banner designer quote"
      locale={pageContext.language}
      template="collaborate"
    >
      <Header black blackResponsive>
        <Banner />
      </Header>
      <main>
        {location.search === '?success=true' ? (
          <Success />
        ) : (
          <Content location={location} />
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
